import { useState, useEffect } from 'react'
import tw from 'twin.macro'

import { useNavigation } from '@/hooks/use-navigation'

import { Icon } from '@/atoms/icon'
import { Container } from '@/atoms/container'
import { AHref } from '@/atoms/a-href'
import { useWebsite } from '@/hooks/use-website'

function TimeTicker() {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const ticker = setInterval(() => {
      setTime(new Date())
    }, 60000)

    return () => {
      clearInterval(ticker)
    }
  }, [])

  return (
    <div className="flex" data-testid="topbar-clock">
      <Icon variant="outline" icon="clock" tw="w-4 h-4 mr-2" />
      <span suppressHydrationWarning>
        {time.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
    </div>
  )
}

/**
 * Desktop top bar with clock, search and navigation links
 */
function TopBar() {
  const data = useNavigation('NavBarGlobal')
  const website = useWebsite()

  return (
    <div className="hidden lg:block py-3 bg-theme-accent w-full">
      <Container tw="flex justify-between text-xs">
        <TimeTicker />
        <div className="flex">
          <nav>
            {data && (
              <ul className="flex uppercase space-x-4 lg:space-x-6">
                {data.map(({ id, label, url }) => {
                  if (!url) return null
                  return (
                    <AHref
                      to={url}
                      key={id}
                      theme="accent"
                      variant="contained"
                      tw="p-0 cursor-pointer"
                      website={website}>
                      <span>{label}</span>
                    </AHref>
                  )
                })}
              </ul>
            )}
          </nav>
        </div>
      </Container>
    </div>
  )
}

export { TopBar }
