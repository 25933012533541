import Link from 'next/link'

interface INextLink {
  to: string
  children?: React.ReactNode
  disabled?: boolean
  scroll?: boolean
  rel?: 'noopener noreferrer' | 'noopener' | 'noreferrer'
}

export function NextLink({
  to,
  disabled = false,
  scroll,
  children,
  ...rest
}: INextLink) {
  if (disabled) {
    return <span {...rest}>{children}</span>
  }

  if (!to) return null

  if (to.includes('https')) {
    return (
      <a
        href={to}
        target="_blank"
        {...rest}
        tw="focus:outline-none focus:ring-2 focus:ring-theme-secondary hover:cursor-pointer">
        {children}
      </a>
    )
  }

  return (
    <Link href={to} scroll={scroll}>
      <a
        {...rest}
        tw="focus:outline-none focus:ring-2 focus:ring-theme-secondary hover:cursor-pointer">
        {children}
      </a>
    </Link>
  )
}
