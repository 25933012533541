import tw, { TwStyle } from 'twin.macro'
import Link from 'next/link'

import { ReactNode } from 'react'

type Themes = 'default' | 'accent'
type Variants = 'contained' | 'inverse' | 'text'

const themes: Record<Themes, TwStyle> = {
  default: {
    contained: tw`text-theme-secondary bg-white`,
    inverse: tw`text-white border border-white`,
    text: `text-theme-secondary border-transparent`,
  },
  accent: {
    contained: tw`text-white bg-theme-accent border-theme-accent`,
    inverse: tw`text-theme-accent border border-theme-accent hover:(bg-theme-accent text-white)`,
    text: tw`text-theme-accent border-transparent hover:text-theme-accent`,
  },
}

const styles = {
  button: ({ theme, variant }: { theme: Themes; variant: Variants }) => [
    themes[theme][variant],
  ],
}

function AHref({
  to,
  variant = 'contained',
  theme = 'default',
  fullWidth = false,
  children,
  disabled,
  forceFocus = false,
  outlineAccent = false,
  website,
  ...rest
}: {
  classes?: string
  to: string
  theme: Themes
  variant: Variants
  forceFocus?: boolean
  fullWidth?: true | false
  children?: ReactNode
  activeClass?: string
  className?: string
  disabled?: boolean
  outlineAccent?: boolean
  website?: string
}) {
  if (!to) return null

  const isExternal = to.includes('https')

  if (disabled) {
    return (
      <span
        css={[
          tw`p-2 text-center border focus:(outline-none ring-2 ring-red-500 border-red-500)`,
          outlineAccent &&
            tw`focus:(outline-none ring-theme-accent)`,
          website && website === 'crockfords' &&
            tw`focus:(outline-none ring-2 ring-white border-white)`,
          fullWidth && tw`w-full`,
          styles.button({ theme, variant }),
          website &&
            website === 'crockfords' &&
            theme === 'default' &&
            tw`text-theme-accent border-theme-accent hover:(bg-theme-accent text-white)`,
          website &&
            website === 'crockfords' &&
            theme === 'accent' &&
            tw`text-white border-white hover:(bg-white text-theme-accent)`,
        ]}
        {...rest}>
        {children}
      </span>
    )
  }

  return (
    <Link href={to} passHref={isExternal || forceFocus}>
      <a
        target={isExternal ? '_blank' : '_self'}
        css={[
          tw`p-2 text-center focus:(outline-none ring-2 ring-red-500 border-red-500) hover:cursor-pointer`,
          website && website === 'crockfords' && tw`uppercase focus:(ring-white border-white)`,
          outlineAccent && tw`focus:ring-theme-accent`,
          fullWidth && tw`w-full`,
          styles.button({ theme, variant }),
          website &&
            website === 'crockfords' &&
            theme === 'default' &&
            tw`text-theme-accent border-theme-accent hover:(bg-theme-accent text-white)`,
          website &&
            website === 'crockfords' &&
            theme === 'accent' &&
            tw`text-white border-white hover:(bg-white text-theme-accent)`,
        ]}
        {...rest}>
        {children}
      </a>
    </Link>
  )
}

export { AHref }
