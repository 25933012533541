
import { theme } from 'twin.macro'
import { useWindowSize } from '@/hooks/use-window-size'

const breakPoints = theme('screens')

export function useBreakPoint(size: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
  const { width } = useWindowSize()
  /* @ts-ignore */
  const screenMd = Number.parseInt(breakPoints[size].replace('px', ''))

  if (width === undefined) return undefined

  return width < screenMd ? true : false 
}

