export const GentingLogoWhite = () => {
  return (
    <div className="mx-7 mb-10">
      <svg
        id="Genting_Logo"
        data-name="Genting Logo"
        xmlns="http://www.w3.org/2000/svg"
        width="250"
        height="33.843"
        viewBox="0 0 250 33.843"
      >
        <g
          id="Genting_Casinos"
          data-name="Genting Casinos"
          transform="translate(45.45 8.75)"
        >
          <path
            id="Path_422"
            data-name="Path 422"
            d="M122.665,109.381h5.648V115.8a8.523,8.523,0,0,1-1.049.795,7.185,7.185,0,0,1-1.375.689,10.154,10.154,0,0,1-1.751.483,11.976,11.976,0,0,1-2.171.181,8.62,8.62,0,0,1-3.391-.639,7.674,7.674,0,0,1-2.583-1.764,7.547,7.547,0,0,1-1.642-2.655,9.532,9.532,0,0,1-.566-3.343,9.142,9.142,0,0,1,.591-3.306,8.225,8.225,0,0,1,1.653-2.693,7.761,7.761,0,0,1,2.548-1.812,8.659,8.659,0,0,1,5.587-.361,7.491,7.491,0,0,1,1.777.773,6.647,6.647,0,0,1,1.289,1.025,10.047,10.047,0,0,1,.893,1.063l-1.641,1.4a8.86,8.86,0,0,0-.7-.857,5.131,5.131,0,0,0-.965-.809,5.7,5.7,0,0,0-1.267-.6,5.071,5.071,0,0,0-1.627-.241,5.48,5.48,0,0,0-2.392.508,5.352,5.352,0,0,0-1.8,1.375,6.227,6.227,0,0,0-1.135,2.04,7.664,7.664,0,0,0-.4,2.475,7.255,7.255,0,0,0,.435,2.559,5.9,5.9,0,0,0,1.218,1.991,5.644,5.644,0,0,0,4.189,1.774,7.871,7.871,0,0,0,2.379-.313,5.315,5.315,0,0,0,1.678-.87v-3.283h-3.429Z"
            transform="translate(-113.783 -101.053)"
            fill="#fff"
          />
          <path
            id="Path_423"
            data-name="Path 423"
            d="M133.486,101.5h10.138v2.052h-7.819v4.683h5.889v2.077h-5.889v5.212h8.4V117.6H133.486Z"
            transform="translate(-115.431 -101.088)"
            fill="#fff"
          />
          <path
            id="Path_424"
            data-name="Path 424"
            d="M147.687,101.5h2.34l8.788,12.576V101.5h2.269v16.1h-2.317L149.953,105v12.6h-2.267Z"
            transform="translate(-116.618 -101.088)"
            fill="#fff"
          />
          <path
            id="Path_425"
            data-name="Path 425"
            d="M170.71,103.527h-5.77V101.5h13.833v2.028h-5.745V117.6H170.71Z"
            transform="translate(-118.061 -101.088)"
            fill="#fff"
          />
          <rect
            id="Rectangle_220"
            data-name="Rectangle 220"
            width="2.317"
            height="16.101"
            transform="translate(63.127 0.411)"
            fill="#fff"
          />
          <path
            id="Path_426"
            data-name="Path 426"
            d="M189.2,101.5h2.342l8.788,12.576V101.5H202.6v16.1h-2.317L191.472,105v12.6H189.2Z"
            transform="translate(-120.09 -101.088)"
            fill="#fff"
          />
          <path
            id="Path_427"
            data-name="Path 427"
            d="M216,109.381h5.65V115.8a8.976,8.976,0,0,1-1.05.795,7.331,7.331,0,0,1-1.376.689,10.223,10.223,0,0,1-1.749.483,12.018,12.018,0,0,1-2.175.181,8.634,8.634,0,0,1-3.391-.639,7.705,7.705,0,0,1-2.584-1.764,7.625,7.625,0,0,1-1.641-2.655,9.574,9.574,0,0,1-.567-3.343,9.181,9.181,0,0,1,.592-3.306,8.256,8.256,0,0,1,1.653-2.693,7.78,7.78,0,0,1,2.548-1.812,8.664,8.664,0,0,1,5.589-.361,7.4,7.4,0,0,1,1.774.773,6.689,6.689,0,0,1,1.291,1.025,9.94,9.94,0,0,1,.893,1.063l-1.64,1.4a8.729,8.729,0,0,0-.7-.857,5.1,5.1,0,0,0-.965-.809,5.746,5.746,0,0,0-1.266-.6,5.1,5.1,0,0,0-1.63-.241,5.475,5.475,0,0,0-2.39.508,5.3,5.3,0,0,0-1.8,1.375,6.178,6.178,0,0,0-1.135,2.04,7.69,7.69,0,0,0-.4,2.475,7.235,7.235,0,0,0,.436,2.559,5.9,5.9,0,0,0,1.219,1.991,5.64,5.64,0,0,0,4.189,1.774,7.867,7.867,0,0,0,2.379-.313,5.325,5.325,0,0,0,1.676-.87v-3.283H216Z"
            transform="translate(-121.588 -101.053)"
            fill="#fff"
          />
          <path
            id="Path_428"
            data-name="Path 428"
            d="M245.19,114.74a5.645,5.645,0,0,1-.822.989,7.331,7.331,0,0,1-1.35,1.05,8.345,8.345,0,0,1-1.835.835,7.425,7.425,0,0,1-2.293.337,8.22,8.22,0,0,1-3.26-.628,7.5,7.5,0,0,1-2.535-1.75,8.005,8.005,0,0,1-1.653-2.655,9.621,9.621,0,0,1,0-6.65,8.4,8.4,0,0,1,1.653-2.705,7.647,7.647,0,0,1,2.548-1.823,7.994,7.994,0,0,1,3.294-.663,7.587,7.587,0,0,1,4.067,1.1,6.939,6.939,0,0,1,1.3,1.026,7.792,7.792,0,0,1,.881,1.063l-1.642,1.4a9.991,9.991,0,0,0-.688-.857,4.763,4.763,0,0,0-.952-.809,5.532,5.532,0,0,0-1.28-.6,5.252,5.252,0,0,0-1.666-.241,5.274,5.274,0,0,0-4.163,1.882,6.1,6.1,0,0,0-1.123,2.027,7.747,7.747,0,0,0-.388,2.463,7.425,7.425,0,0,0,.4,2.451,6.125,6.125,0,0,0,1.123,1.991,5.116,5.116,0,0,0,1.761,1.327,5.42,5.42,0,0,0,2.318.484,5.6,5.6,0,0,0,1.869-.279,5.4,5.4,0,0,0,1.33-.663,5.269,5.269,0,0,0,.893-.785c.232-.265.43-.487.59-.665Z"
            transform="translate(-123.573 -101.053)"
            fill="#fff"
          />
          <path
            id="Path_429"
            data-name="Path 429"
            d="M253.722,101.5h2.364l6.3,16.1H259.8l-1.666-4.49h-6.614l-1.666,4.49H247.42Zm3.717,9.654-2.609-7.072-2.629,7.072Z"
            transform="translate(-124.959 -101.088)"
            fill="#fff"
          />
          <path
            id="Path_430"
            data-name="Path 430"
            d="M266.13,113.653c.228.257.479.517.762.783a5.226,5.226,0,0,0,2.136,1.22,4.95,4.95,0,0,0,1.424.193,3.852,3.852,0,0,0,2.487-.724,2.387,2.387,0,0,0,.892-1.956,2.22,2.22,0,0,0-1.014-1.992A8.823,8.823,0,0,0,269.9,110.1a9.212,9.212,0,0,1-1.884-.6,5.437,5.437,0,0,1-1.459-.968,4.127,4.127,0,0,1-.954-1.34,4.223,4.223,0,0,1-.337-1.726,4.291,4.291,0,0,1,.337-1.689,3.9,3.9,0,0,1,1.015-1.4,5.05,5.05,0,0,1,1.69-.967,6.91,6.91,0,0,1,2.342-.362,7.235,7.235,0,0,1,2.04.254,6.243,6.243,0,0,1,1.459.616,4.481,4.481,0,0,1,.966.747q.362.389.554.629l-1.543,1.425c-.1-.114-.242-.262-.434-.448a4.09,4.09,0,0,0-.737-.542,4.811,4.811,0,0,0-1.038-.447,4.693,4.693,0,0,0-1.364-.181,3.843,3.843,0,0,0-1.327.2,2.8,2.8,0,0,0-.917.533,2.01,2.01,0,0,0-.531.734,2.143,2.143,0,0,0-.167.822,1.957,1.957,0,0,0,.25,1.026,2.443,2.443,0,0,0,.738.748,4.714,4.714,0,0,0,1.192.554q.716.231,1.632.448a12.3,12.3,0,0,1,1.75.567,5.386,5.386,0,0,1,1.532.941,4.29,4.29,0,0,1,1.073,1.448,4.913,4.913,0,0,1,.4,2.088,4.21,4.21,0,0,1-.434,1.907,4.743,4.743,0,0,1-1.2,1.508,5.457,5.457,0,0,1-1.8.99,7.009,7.009,0,0,1-2.246.35,7.543,7.543,0,0,1-2.064-.265,8.421,8.421,0,0,1-1.691-.677,7.138,7.138,0,0,1-1.325-.9,6.576,6.576,0,0,1-.932-.98Z"
            transform="translate(-126.386 -101.051)"
            fill="#fff"
          />
          <path
            id="Path_431"
            data-name="Path 431"
            d="M325.89,113.653c.227.257.479.517.762.783a5.226,5.226,0,0,0,2.136,1.22,4.95,4.95,0,0,0,1.424.193,3.851,3.851,0,0,0,2.487-.724,2.387,2.387,0,0,0,.892-1.956,2.22,2.22,0,0,0-1.013-1.992,8.823,8.823,0,0,0-2.921-1.074,9.187,9.187,0,0,1-1.883-.6,5.444,5.444,0,0,1-1.46-.968,4.11,4.11,0,0,1-.953-1.34,4.21,4.21,0,0,1-.338-1.726,4.277,4.277,0,0,1,.338-1.689,3.883,3.883,0,0,1,1.014-1.4,5.045,5.045,0,0,1,1.691-.967,6.9,6.9,0,0,1,2.341-.362,7.231,7.231,0,0,1,2.04.254,6.243,6.243,0,0,1,1.459.616,4.482,4.482,0,0,1,.966.747q.361.389.554.629l-1.543,1.425c-.1-.114-.242-.262-.434-.448a4.127,4.127,0,0,0-.738-.542,4.813,4.813,0,0,0-1.038-.447,4.694,4.694,0,0,0-1.365-.181,3.843,3.843,0,0,0-1.327.2,2.785,2.785,0,0,0-.916.533,2.022,2.022,0,0,0-.532.734,2.144,2.144,0,0,0-.167.822,1.957,1.957,0,0,0,.25,1.026,2.464,2.464,0,0,0,.738.748,4.706,4.706,0,0,0,1.193.554q.715.231,1.631.448a12.344,12.344,0,0,1,1.751.567,5.393,5.393,0,0,1,1.531.941,4.278,4.278,0,0,1,1.073,1.448,4.913,4.913,0,0,1,.4,2.088,4.221,4.221,0,0,1-.433,1.907,4.733,4.733,0,0,1-1.2,1.508,5.456,5.456,0,0,1-1.8.99,7.007,7.007,0,0,1-2.245.35,7.55,7.55,0,0,1-2.065-.265,8.423,8.423,0,0,1-1.691-.677,7.142,7.142,0,0,1-1.325-.9,6.568,6.568,0,0,1-.931-.98Z"
            transform="translate(-131.384 -101.051)"
            fill="#fff"
          />
          <rect
            id="Rectangle_221"
            data-name="Rectangle 221"
            width="2.317"
            height="16.101"
            transform="translate(153.095 0.411)"
            fill="#fff"
          />
          <path
            id="Path_432"
            data-name="Path 432"
            d="M287.381,101.5h2.342l8.787,12.576V101.5h2.27v16.1h-2.317L289.65,105v12.6h-2.269Z"
            transform="translate(-128.301 -101.088)"
            fill="#fff"
          />
          <path
            id="Path_433"
            data-name="Path 433"
            d="M313.321,117.973a7.9,7.9,0,0,1-3.235-.663,8.226,8.226,0,0,1-2.607-1.811,8.35,8.35,0,0,1-1.74-2.69,8.761,8.761,0,0,1-.625-3.308,8.646,8.646,0,0,1,.625-3.294,8.52,8.52,0,0,1,1.726-2.681,8.1,8.1,0,0,1,2.61-1.81,8.278,8.278,0,0,1,6.494,0,8.227,8.227,0,0,1,2.616,1.81,8.4,8.4,0,0,1,1.74,2.681,8.649,8.649,0,0,1,.628,3.294,8.764,8.764,0,0,1-.628,3.308,8.35,8.35,0,0,1-1.74,2.69,8.215,8.215,0,0,1-2.616,1.811,8,8,0,0,1-3.248.663m0-2.172a5.507,5.507,0,0,0,4.237-1.882,6.193,6.193,0,0,0,1.169-2.018,7.148,7.148,0,0,0,.411-2.427,7.234,7.234,0,0,0-.411-2.449,6.212,6.212,0,0,0-1.169-2.016,5.452,5.452,0,0,0-1.836-1.362,5.656,5.656,0,0,0-2.4-.5,5.567,5.567,0,0,0-2.39.5,5.493,5.493,0,0,0-1.825,1.362,6.28,6.28,0,0,0-1.169,2.016,7.234,7.234,0,0,0-.411,2.449,7.148,7.148,0,0,0,.411,2.427,6.26,6.26,0,0,0,1.169,2.018,5.657,5.657,0,0,0,1.825,1.375,5.491,5.491,0,0,0,2.39.508"
            transform="translate(-129.784 -101.051)"
            fill="#fff"
          />
        </g>
        <path
          id="Logo"
          d="M235,66.344v2.807h1.835a12.88,12.88,0,0,1-3.721,7.761,13.11,13.11,0,0,1-2.34,1.858q-.3-.625-.57-1.273a24.772,24.772,0,0,1-1.864-8.346h2.482a22.4,22.4,0,0,0,1.442,6.815,11.755,11.755,0,0,0,2.037-2.776,19.953,19.953,0,0,1,.068-10.758,11.691,11.691,0,0,0-2.005-2.8,22.462,22.462,0,0,0-1.52,6.711h-2.485a24.832,24.832,0,0,1,2.549-9.538,13.111,13.111,0,0,1,2.207,1.776,12.885,12.885,0,0,1,3.316,5.655h2.893a15.787,15.787,0,0,0-7.028-9.869q.525-.814,1.115-1.585l-2.2-1.741c-.506.661-.981,1.348-1.429,2.055a15.831,15.831,0,0,0-11.666,0c-.446-.7-.923-1.391-1.427-2.052l-2.2,1.741c.393.515.764,1.046,1.114,1.585a15.769,15.769,0,0,0,.164,26.858q-.641,1.019-1.374,1.971l2.24,1.688A27.992,27.992,0,0,0,218.3,82.47a15.815,15.815,0,0,0,11.305,0,27.41,27.41,0,0,0,1.665,2.416l2.239-1.688q-.735-.954-1.374-1.971a15.752,15.752,0,0,0,7.585-13.467V66.344ZM214.787,76.912a12.964,12.964,0,0,1,0-18.329,13.117,13.117,0,0,1,2.208-1.776,24.83,24.83,0,0,1,2.547,9.538H217.06a22.492,22.492,0,0,0-1.522-6.711,11.658,11.658,0,0,0-2,2.8,19.93,19.93,0,0,1,.066,10.758,11.81,11.81,0,0,0,2.038,2.776,22.424,22.424,0,0,0,1.442-6.815h2.482A24.708,24.708,0,0,1,217.7,77.5c-.177.433-.367.856-.568,1.273a13.185,13.185,0,0,1-2.341-1.858m9.166,3.8a12.958,12.958,0,0,1-4.294-.726,27.861,27.861,0,0,0-.15-24.416,13.066,13.066,0,0,1,8.888,0,27.869,27.869,0,0,0-.15,24.416,12.969,12.969,0,0,1-4.293.726"
          transform="translate(-208.185 -51.042)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};
