const STAGE: 'string' = process.env.NEXT_PUBLIC_APP_ENV

if (['local', 'development'].includes(STAGE)) {
  process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0
}

const config: any = {
  // For local development e.g. localhost:3000
  local: {
    STAGE: 'local',
    host: 'http://localhost:1337/api',
    captchaSiteKey: '6LfKr08fAAAAAIsvzREBIQHmULMA5XrXwUPRk9md',
    jackpotsApi: 'https://67hmeicvkh.execute-api.eu-west-2.amazonaws.com',
    bookingsApi: 'https://api.liveres.co.uk/events/v1',
    registrationApi:
      'https://ls94j5jhc0.execute-api.eu-west-2.amazonaws.com/dev',
    jackpotApi: 'https://jfqgq1ez5l.execute-api.eu-west-2.amazonaws.com/dev',
    worldpayApi:
      'https://secure-test.worldpay.com/jsp/merchant/xml/paymentService.jsp',
  },
  // gnt-uk-stg - used for testing and preview deploys in a live environment before staging
  development: {
    STAGE: 'development',
    host: 'http://gnt-uk-dev-strapi-lb-407460608.eu-west-2.elb.amazonaws.com/api',
    captchaSiteKey: '6LfKr08fAAAAAIsvzREBIQHmULMA5XrXwUPRk9md',
    jackpotsApi: 'https://67hmeicvkh.execute-api.eu-west-2.amazonaws.com',
    bookingsApi: 'https://api.liveres.co.uk/events/v1',
    registrationApi:
      'https://ls94j5jhc0.execute-api.eu-west-2.amazonaws.com/dev',
    jackpotApi: 'https://jfqgq1ez5l.execute-api.eu-west-2.amazonaws.com/dev',
    worldpayApi:
      'https://secure-test.worldpay.com/jsp/merchant/xml/paymentService.jsp',
  },
  // gnt-uk-stg - used for testing in a live environment before production
  staging: {
    STAGE: 'staging',
    host: 'https://retail-staging-v4.gentingcasinos.co.uk/api',
    captchaSiteKey: '6LfKr08fAAAAAIsvzREBIQHmULMA5XrXwUPRk9md',
    jackpotsApi: 'https://67hmeicvkh.execute-api.eu-west-2.amazonaws.com',
    bookingsApi: 'https://api.liveres.co.uk/events/v1',
    registrationApi:
      'https://ls94j5jhc0.execute-api.eu-west-2.amazonaws.com/dev',
    jackpotApi: 'https://jfqgq1ez5l.execute-api.eu-west-2.amazonaws.com/dev',
    worldpayApi:
      'https://secure.worldpay.com/jsp/merchant/xml/paymentService.jsp',
  },
  // gnt-uk-prd - used in production
  production: {
    STAGE: 'production',
    host: 'https://retail-production-v4.gentingcasinos.co.uk/api',
    captchaSiteKey: '6LfKr08fAAAAAIsvzREBIQHmULMA5XrXwUPRk9md',
    jackpotsApi: 'https://67hmeicvkh.execute-api.eu-west-2.amazonaws.com',
    registrationApi:
      'https://vae4an0qkj.execute-api.eu-west-2.amazonaws.com/prod',
    jackpotApi: 'https://y3iyhaug51.execute-api.eu-west-2.amazonaws.com/prod',
    worldpayApi:
      'https://secure.worldpay.com/jsp/merchant/xml/paymentService.jsp',
  },
}

// leaving the log so we know it works in the different environments

export default {
  // Pull in config based on staging environment
  ...config[STAGE],
}
