import dynamic from 'next/dynamic'
import tw from 'twin.macro'

import { useBreakPoint } from '@/hooks/use-break-point'

import { CasinoText } from '@/atoms/casino-text'
import { RedLogoGenting } from '@/atoms/redlogo'
import { Container } from '@/atoms/container'
import { NextLink } from '@/atoms/next-link'

const LeftHandMenu = dynamic<{}>(
  () => import('./left-hand-menu').then((module) => module.LeftHandMenu),
  { ssr: false }
)

export const TopNav = () => {
  const isMediumScreen = useBreakPoint('lg')

  return (
    <div tw="bg-theme-secondary">
      <Container tw="flex flex-row items-center justify-between py-2 lg:border-b lg:border-gray-100 lg:border-opacity-50">
        <div tw="flex-1">
          {isMediumScreen ? (
            <LeftHandMenu />
          ) : (
            <div tw="flex flex-1 ml-auto justify-start">
              <NextLink
            to="/casinos/"
            tw="bg-theme-accent py-1">
            <span tw="mx-5 text-xs">Find a Casino</span>
          </NextLink>
            </div>
          )}
        </div>
        <div tw="flex-1">
          <NextLink to="/" tw="flex flex-row items-center justify-center">
            <RedLogoGenting />
            <div tw="ml-3 hidden lg:block">
              <CasinoText />
            </div>
          </NextLink>
        </div>
        <div tw="flex-1 ml-auto flex justify-end">
          <NextLink
            to="/register/"
            tw="bg-theme-accent py-1">
            <span tw="mx-5 text-xs">Register</span>
          </NextLink>
        </div>
      </Container>
      <div tw="flex lg:hidden bg-theme-accent border-b border-white">
        <NextLink
          to={'/casinos'}
          tw="w-1/2 py-2 flex justify-center items-center">
          <span tw="pr-2">Find a Casino</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12">
            <path
              id="Search_icon"
              data-name="Search icon"
              d="M5.836-.117A.542.542,0,0,1,6,.281a.542.542,0,0,1-.164.4l-.656.656a.566.566,0,0,1-.8,0L2.039-1.008a.542.542,0,0,1-.164-.4v-.375a4.723,4.723,0,0,1-3,1.031,4.735,4.735,0,0,1-2.449-.656,4.925,4.925,0,0,1-1.77-1.77A4.735,4.735,0,0,1-6-5.625a4.735,4.735,0,0,1,.656-2.449,4.925,4.925,0,0,1,1.77-1.77A4.735,4.735,0,0,1-1.125-10.5a4.735,4.735,0,0,1,2.449.656,4.925,4.925,0,0,1,1.77,1.77A4.735,4.735,0,0,1,3.75-5.625a4.723,4.723,0,0,1-1.031,3h.375a.542.542,0,0,1,.4.164ZM-1.125-2.625a2.975,2.975,0,0,0,1.512-.4,2.919,2.919,0,0,0,1.09-1.09,2.975,2.975,0,0,0,.4-1.512,2.975,2.975,0,0,0-.4-1.512,2.919,2.919,0,0,0-1.09-1.09,2.975,2.975,0,0,0-1.512-.4,2.975,2.975,0,0,0-1.512.4,2.919,2.919,0,0,0-1.09,1.09,2.975,2.975,0,0,0-.4,1.512,2.975,2.975,0,0,0,.4,1.512,2.919,2.919,0,0,0,1.09,1.09A2.975,2.975,0,0,0-1.125-2.625Z"
              transform="translate(6 10.5)"
              fill="#fff"
            />
          </svg>
        </NextLink>
        <NextLink
          to="https://www.gentingcasino.com/?ar=10037516"
          rel="nofollow noopener noreferrer"
          tw="w-1/2 py-2 flex justify-center items-center bg-[#055519]">
          <span tw="pr-2">Play Online Now</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.509"
            height="12.126"
            viewBox="0 0 14.509 12.126">
            <g
              id="Group_1064"
              data-name="Group 1064"
              transform="translate(-323.876 -108.875)">
              <g
                id="Group_1064-2"
                data-name="Group 1064"
                transform="translate(324.001 109)">
                <path
                  id="Path_1301"
                  data-name="Path 1301"
                  d="M694.964,73.735h-13.7a.28.28,0,0,0-.28.279V85.331a.28.28,0,0,0,.28.279h13.7a.28.28,0,0,0,.28-.279V74.014A.28.28,0,0,0,694.964,73.735Zm-5.945,9.395h-1.807a1.193,1.193,0,0,1-1.191-1.191V77.67a1.193,1.193,0,0,1,1.191-1.191h1.807a1.192,1.192,0,0,1,1.191,1.191v4.269A1.192,1.192,0,0,1,689.019,83.13Zm-7.463-5.46a1.192,1.192,0,0,1,1.191-1.191h1.513a1.192,1.192,0,0,1,1.191,1.191v4.269a1.192,1.192,0,0,1-1.191,1.191h-1.513a1.192,1.192,0,0,1-1.191-1.191Zm13.123,4.269a1.192,1.192,0,0,1-1.191,1.191h-1.516a1.193,1.193,0,0,1-1.191-1.191V77.67a1.193,1.193,0,0,1,1.191-1.191h1.516a1.192,1.192,0,0,1,1.191,1.191Zm.006-7.644v1.617H681.544V74.294ZM681.544,85.051v-1.36h13.141v1.36Z"
                  transform="translate(-680.985 -73.735)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="0.25"
                />
                <path
                  id="Path_1302"
                  data-name="Path 1302"
                  d="M684.683,83.269h.851a4.592,4.592,0,0,0-.523,1.125,5.856,5.856,0,0,0-.264,1.27h-.355v.62h1.381v-.62H685.4a4.955,4.955,0,0,1,.107-.9,5.711,5.711,0,0,1,.271-.927,3.026,3.026,0,0,1,.37-.7v-.466h-1.939v1.1h.474Z"
                  transform="translate(-682.607 -78.226)"
                  fill="#fff"
                />
                <path
                  id="Path_1303"
                  data-name="Path 1303"
                  d="M693.588,83.769h.474v-.5h.851a4.63,4.63,0,0,0-.523,1.125,5.883,5.883,0,0,0-.264,1.27h-.355v.62h1.381v-.62h-.372a4.9,4.9,0,0,1,.107-.9,5.668,5.668,0,0,1,.271-.927,3.024,3.024,0,0,1,.37-.7v-.466h-1.939Z"
                  transform="translate(-687.324 -78.226)"
                  fill="#fff"
                />
                <path
                  id="Path_1304"
                  data-name="Path 1304"
                  d="M703.678,84.485a5.981,5.981,0,0,1-.805-1.384,1.787,1.787,0,0,1-.093-.466.111.111,0,0,0-.122-.1.123.123,0,0,0-.114.108,2.414,2.414,0,0,0,.229.815,6.254,6.254,0,0,0,.623,1.084.651.651,0,0,0-.551.678,1.029,1.029,0,0,0,.551.876.806.806,0,0,0,.424.084.956.956,0,0,0,.72-.508.879.879,0,0,0-.014-.89A.888.888,0,0,0,703.678,84.485Zm-.286.39a.28.28,0,0,0-.227.26.067.067,0,0,1-.067.065h0a.066.066,0,0,1-.064-.069.409.409,0,0,1,.335-.387.067.067,0,0,1,.025.131Z"
                  transform="translate(-691.828 -78.16)"
                  fill="#fff"
                />
                <path
                  id="Path_1305"
                  data-name="Path 1305"
                  d="M704.727,83.123a1.189,1.189,0,0,0-.723-.434,1.208,1.208,0,0,0-.731.068,1.166,1.166,0,0,0,.74.714,1.082,1.082,0,0,0,1.044-.14l-.063-.017A.582.582,0,0,1,704.727,83.123Z"
                  transform="translate(-692.195 -78.228)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </NextLink>
      </div>
    </div>
  )
}
