import { useRouter } from 'next/router'

export function useWebsite() {
  const router = useRouter()
  let website = 'genting-casinos'

  if (router.asPath.split('/')[1].includes('the-palm-beach')) {
    // set page props for the palm beach
    website = 'the-palm-beach'
  }

  if (router.asPath.split('/')[1].includes('crockfords')) {
    // set page props for the palm beach
    website = 'crockfords'
  }

  if (website === undefined) {
    throw new Error('useWebsite must be used within a WebsiteProvider')
  }
  return website
}
