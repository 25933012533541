import tw, { css } from 'twin.macro'

import { useWebsite } from '@/hooks/use-website'

function CompanyInformationLondon() {

  function getCurrentYear() {
    const currentDate = new Date();

    return currentDate.getFullYear()
  }

  const website = useWebsite()

  return (
    <section css={[
      tw`max-w-4xl m-auto px-12 md:px-6 space-y-8 text-center text-xxs text-theme-primary leading-5`,
      website === 'the-palm-beach' && tw`text-theme-muted`,
    ]}
      >
      <p>Our land based casinos are all operated by Genting Casinos UK limited. <br className="hidden md:inline-block" /> <span className="hidden md:inline-block">Genting Casinos UK Limited <span className="hidden md:inline-block">|</span></span><br className="inline-block md:hidden" /> Company Number: 01519689 <span className="hidden md:inline-block">|</span><br className="inline-block lg:hidden" /> Registered Office: Genting Club Star City,<br className="inline-block md:hidden" /> Watson Road, Birmingham, England, B75SA<br />
      Registered in England <span className="md:inline-block">|</span><br className="inline-block md:hidden" /> VAT number: 482826028</p>

      <p>Genting Casinos UK Limited is licensed and regulated by the UK Gambling Commission (licence number 537). <br className="inline-block" /> Details of its current licence status as recorded on the Gambling Commission&apos;s website can be found <a tw="hover:(text-theme-accent bg-white)" href="https://www.gamblingcommission.gov.uk/public-register/business/detail/537" target="_blank" rel="noopener noreferrer">here</a>.</p>
      <p>Copyright Genting Casinos UK Limited, {`${getCurrentYear()}`}.<br className="inline-block md:hidden" /> All Rights Reserved.</p>
    </section>
  )
}

export { CompanyInformationLondon }