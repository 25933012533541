import { memo } from 'react'

import { useBreakPoint } from '@/hooks/use-break-point'
import { useWebsite } from '@/hooks/use-website'

import { TopBar } from '@/organisms/top-bar'
import { TopNav } from '@/organisms/top-nav'
import { DesktopMenu } from '@/organisms/desktop-menu'
import { TopNavLC } from '@/components/london-clubs/organisms/top-nav'

const Header = memo(function MemorizedHeader() {
  const website = useWebsite()
  const isMediumScreen = useBreakPoint('lg')

  return (
    <header className="relative bg-theme-secondary">
      {!isMediumScreen && <TopBar />}
      {['the-palm-beach', 'crockfords'].includes(website) ? (
        <TopNavLC />
      ) : (
        <TopNav />
      )}
      <DesktopMenu />
    </header>
  )
})

export { Header }
