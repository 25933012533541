import { ReactNode } from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  id?: string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  variant?: string;
  children: ReactNode;
};

const styles: { [key: string]: TwStyle } = {
  h1: tw`uppercase text-6xl font-light tracking-super-wide leading-26`,
  h2: tw`uppercase text-4.5xl font-light tracking-widest leading-14`,
  h3: tw`uppercase text-3xl font-light tracking-wide`,
  h4: tw`uppercase text-xl font-light tracking-wide`,
  h5: tw`uppercase font-light tracking-widest`,
}

function Heading({ as, variant = "h2", children, ...rest }: Props) {
  const Component = as || "h2";

  return (
    <Component css={styles[variant]} {...rest}>
      {children}
    </Component>
  );
}

export { Heading };
