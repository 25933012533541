import { useWebsite } from '@/hooks/use-website'
import { useSingle } from '@/hooks/use-cms-data'

export function useNavigation(nav) {
  const website = useWebsite()
  const { data } = useSingle('navigations', {
    filters: { applications: { slug: website } },
  })

  // if you want a specific navigation then pass it as
  // as string
  if (nav && data) {
    const navData = data?.data[0]?.attributes?.[nav]

    return navData
  }

  // otherwise you get all of them passed back
  return data
}
