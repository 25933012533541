import { ReactNode } from 'react'
import tw, { css, theme as twTheme } from 'twin.macro'

import { useWebsite } from '@/hooks/use-website'

type Theme = 'default' | 'accent' | 'content-with-image'

type Props = {
  theme?: Theme
  children: string | ReactNode
}

const styles = {
  root: (theme: Theme, website: string) => [
    css`
      & blockquote,
      & dl,
      & dd,
      & hr,
      & figure,
      & p,
      & pre {
        margin-bottom: 1.25rem;
      }

      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        text-transform: uppercase;
        font-weight: font-light;
        letter-spacing: 0.025em;
      }

      & h1 {
        font-size: 2.5rem;
        line-height: 1;
      }

      & h2 {
        font-size: 1.5rem;
      }

      & h3 {
        font-size: 1.3rem;
      }

      & h4 {
        font-size: 1rem;
      }

      & h5 {
        font-size: 0.8rem;
      }

      & h6 {
        font-size: 0.7rem;
      }

      & a {
        text-decoration: underline;
      }

      ul,
      ol {
        display: block;
        margin: 1.25rem 0;
        list-style-position: outside;
        margin-left: 1.25rem;
      }

      ul {
        list-style-type: disc;
      }

      ol {
        list-style-type: decimal;
      }

      ul ul,
      ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin: 0;
        margin-left: 1.5rem;
      }

      ol ol,
      ul ol {
        list-style-type: lower-roman;
        list-style-position: inside;
        margin: 0;
        margin-left: 1.5rem;
      }

      li {
        display: list-item;
        margin-bottom: 0.5rem;
      }

      table ul,
      table ol {
        margin-top: 0;
      }
    `,

    theme === 'default' &&
      css`
        color: ${website === 'crockfords'
          ? 'black'
          : twTheme`colors.theme.secondary`};

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
          color: ${twTheme`colors.theme.accent`};
        }

        & a {
          color: ${twTheme`colors.theme.accent`};
        }
        li::marker {
          color: ${twTheme`colors.theme.accent`};
        }
      `,
    theme === 'accent' &&
      css`
        color: #fff;

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
          color: #fff;
        }
        li::marker {
          color: #fff;
        }
      `,
    website === 'the-palm-beach' &&
      css`
        h3 {
          font-size: 1rem;
        }
      `,
    theme === 'accent' &&
      website === 'the-palm-beach' &&
      css`
        color: #fff;

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
          color: ${twTheme`colors.theme.accent`};
        }

        & a,
        li::marker {
          color: ${twTheme`colors.theme.accent`};
        }
      `,
    theme === 'content-with-image' &&
      website === 'the-palm-beach' &&
      css`
        h2 {
          line-height: 2rem;
          margin-bottom: 0.5rem;
          color: ${twTheme`colors.theme.accent`};
        }
      `,
  ],
}

export function Typography({ theme = 'default', children, ...rest }: Props) {
  const website = useWebsite()
  return (
    <div css={styles.root(theme, website)} {...rest}>
      {children}
    </div>
  )
}
