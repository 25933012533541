// @ts-nocheck
import {
  Dispatch,
  SetStateAction,
  Fragment,
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useRef,
} from 'react'
import Link from 'next/link'
import { Transition } from '@headlessui/react'
import tw, { css } from 'twin.macro'
import { ChevronRightIcon } from '@heroicons/react/solid'

import { useNavigation } from '@/hooks/use-navigation'
import { useOnClickOutside } from '@/hooks/use-on-click-outside'
import { useWebsite } from '@/hooks/use-website'

import { Container } from '@/atoms/container'

type TMenuItem = {
  id: number
  label: string
  url: string
}

type TFlyOutMenu = TMenuItem & {
  parent: string
  links: TMenuItem[]
}

type TDropDownMenu = TMenuItem & {
  parent: string
  MenuItems?: TMenuItem[]
}

type TAction =
  | { type: 'dropdown'; payload: string }
  | { type: 'flyout'; payload: string }
interface IMenuContext {
  state: typeof initialState
  dispatch: Dispatch<SetStateAction<TAction>>
}

const MenuContext = createContext<IMenuContext | null>(null)

const initialState = {
  dropdownOpen: null,
  flyoutOpen: null,
}

function reducer(state: typeof initialState, action: TAction) {
  switch (action.type) {
    case 'dropdown':
      return {
        flyoutOpen: null,
        dropdownOpen:
          action.payload === state.dropdownOpen ? null : action.payload,
      }
    case 'flyout':
      return {
        ...state,
        flyoutOpen: action.payload === state.flyoutOpen ? null : action.payload,
      }
    case 'reset':
      return {
        ...initialState,
      }
    default:
      throw new Error()
  }
}

function useMenuContext() {
  const context = useContext(MenuContext)

  if (!context) {
    throw new Error(
      `Desktop menu compound components cannot be rendered outside the DesktopMenu component`
    )
  }
  return context
}

function MenuButton({
  label,
  children,
  handleClick,
  open = false,
  ...rest
}: {
  label: string
  children?: ReactNode
  handleClick: () => Dispatch<TAction>
  open: boolean
}) {
  const { state } = useMenuContext()
  const website = useWebsite()

  return (
    <button
      css={[
        tw`flex flex-row items-center justify-center w-full py-3 hover:(font-bold bg-white text-theme-secondary)`,
        website === 'crockfords' && tw`font-baskerville`,
        state.dropdownOpen === label && tw`text-theme-secondary bg-white`,
        website === 'crockfords' &&
          !open &&
          tw`hover:(font-bold bg-[#960E45] text-white)`,
      ]}
      onClick={handleClick}
      {...rest}>
      <span
        data-name={label}
        css={[
          state.dropdownOpen === label && tw`font-bold`,
          /* having bold on hover causes layout shift often chops responsible gaming off therefore
          use flex with the below 'hack' to stop normal/bold text inner shift 
          https://stackoverflow.com/questions/556153/inline-elements-shifting-when-made-bold-on-hover#20249560 */
          css`
            &::before {
              display: block;
              content: attr(data-name);
              font-weight: bold;
              height: 0;
              overflow: hidden;
              visibility: hidden;
            }
          `,
        ]}>
        {label}
      </span>
      {children}
    </button>
  )
}

function MenuLink({
  url,
  label,
  open = false,
}: {
  url: string
  label: string
  open: boolean
}) {
  const { state, dispatch } = useMenuContext()
  const website = useWebsite()

  return (
    <Link href={url} passHref>
      <a
        css={[
          tw`flex flex-col justify-end py-3 hover:font-bold h-full w-full`,
          website === 'crockfords' && tw`font-baskerville`,
          website === 'crockfords' && !open
            ? tw`hover:bg-[#960E45]`
            : tw`hover:(bg-white text-theme-secondary)`,
          state.dropdownOpen === label &&
            tw`font-bold text-theme-secondary bg-white`,
          css`
            &::before {
              display: block;
              content: attr(data-name);
              font-weight: bold;
              height: 0;
              overflow: hidden;
              visibility: hidden;
            }
            &::after {
              margin-top: -1px;
            }
          `,
        ]}
        data-name={label}
        target={url.includes('https') ? '_blank' : '_self'}
        onClick={() => dispatch({ type: 'reset' })}>
        {label}
      </a>
    </Link>
  )
}

function FlyoutMenu({ parent, link }: TFlyOutMenu) {
  const { state } = useMenuContext()

  return (
    <div
      css={[
        tw`absolute left-52`,
        css`
          margin-top: -44px;
        `,
        state.flyoutOpen === parent ? tw`flex` : tw`hidden`,
      ]}>
      <ul className="divide-y divide-theme-secondary w-52 min-w-max px-4 lg:px-5 xl:px-8 bg-white">
        {link.map(({ id, label, url }) => (
          <li key={id}>
            <MenuLink
              url={url}
              label={label}
              open={state.flyoutOpen === parent}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

function DropdownMenu({ parent, MenuItems }: TDropDownMenu) {
  const { state, dispatch } = useMenuContext()
  const website = useWebsite()

  return (
    <Transition
      as={Fragment}
      show={state.dropdownOpen === parent}
      unmount={false}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0">
      <div
        css={[
          tw`absolute top-[44px] left-0 grid auto-cols-max z-50 text-theme-secondary`,
          website === 'crockfords' && tw`text-black`,
          state.dropdownOpen !== parent && tw`hidden`,
        ]}>
        {MenuItems && (
          <ul tw="divide-y divide-theme-secondary w-52 min-w-max px-4 lg:px-5 xl:px-8 bg-white">
            {MenuItems.map(({ id, label, url, link }) => (
              <li key={id}>
                {link.length > 0 ? (
                  <>
                    <MenuButton
                      label={label}
                      tw="flex justify-between"
                      open={state.dropdownOpen === parent}
                      /* @ts-ignore */
                      handleClick={() =>
                        dispatch({ type: 'flyout', payload: label })
                      }>
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="ml-2 h-5 w-5"
                      />
                    </MenuButton>
                    <FlyoutMenu parent={label} link={link} />
                  </>
                ) : (
                  <MenuLink
                    url={url}
                    label={label}
                    open={state.dropdownOpen === parent}
                    tw="hover:(bg-white font-bold text-black)"
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Transition>
  )
}

export function DesktopMenu() {
  /* @ts-ignore */
  const NavRef = useRef()
  const [state, dispatch] = useReducer(reducer, initialState)
  const data = useNavigation('NavbarTop')

  useOnClickOutside(NavRef, () => {
    // this is also needed to stop dispatch firing and causing re-renders
    if (state.flyoutOpen === null && state.dropdownOpen === null) {
      return
    }

    return dispatch({ type: 'reset' })
  })

  return (
    <div ref={NavRef} className="hidden lg:block">
      <MenuContext.Provider
        value={{
          state,
          dispatch,
        }}>
        <Container>
          <nav>
            <ul className="grid grid-flow-col just text-sm">
              {data &&
                data.map(({ id, label, url, MenuItems }) => {
                  if (url)
                    return (
                      <li key={id} tw="relative text-center">
                        <MenuLink url={url} label={label} />
                      </li>
                    )
                  return (
                    <li key={id} tw="relative">
                      <MenuButton
                        label={label}
                        /* @ts-ignore */
                        handleClick={() =>
                          dispatch({ type: 'dropdown', payload: label })
                        }></MenuButton>
                      {MenuItems.length > 0 && (
                        <DropdownMenu parent={label} MenuItems={MenuItems} />
                      )}
                    </li>
                  )
                })}
            </ul>
          </nav>
        </Container>
      </MenuContext.Provider>
    </div>
  )
}
