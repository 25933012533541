function CompanyInformation() {
  return (
    <section className="max-w-4xl m-auto px-12 md:px-6 space-y-8 text-center text-xxs text-theme-muted leading-5">
      <p>
        Genting Casinos UK Limited&nbsp;
        <span className="hidden md:inline-block">|</span>
        <br className="inline-block md:hidden" />
        Company Number: 01519689
        <span className="hidden md:inline-block">|</span>
        <br className="inline-block lg:hidden" /> Registered Office: Genting
        Club Star City,
        <br className="inline-block md:hidden" /> Watson Road, Birmingham,
        England, B75SA
        <br />
        Registered in England <span className="hidden md:inline-block">|</span>
        <br className="inline-block md:hidden" /> VAT number: 482826028
      </p>
      <p>
        Genting Casinos UK Limited is licensed and regulated by the UK Gambling
        Commission{' '}
        <a
          tw="hover:(text-theme-accent bg-white)"
          href="https://www.gamblingcommission.gov.uk/public-register/business/detail/537"
          target="_blank"
          rel="noopener noreferrer">
          (licence number 537)
        </a>
        . Details of its current licence status as recorded on the Gambling
        Commission's website can be found{' '}
        <a
          tw="hover:(text-theme-accent bg-white)"
          href="https://www.gamblingcommission.gov.uk/public-register/business/detail/537"
          target="_blank"
          rel="noopener noreferrer">
          here
        </a>
        .
      </p>
      <p>
        Copyright Genting Casinos UK Limited,
        <br className="inline-block md:hidden" /> All Rights Reserved
      </p>
    </section>
  )
}

export { CompanyInformation }
