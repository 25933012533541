import { ReactNode } from 'react'

import { CookieManagement } from '@/organisms/cookies'
import { Footer } from '@/organisms/footer'
import { Header } from '@/organisms/header'
import { MyGentingApp } from '@/organisms/my-genting-app'

function Layout({
  slug,
  website,
  children,
}: {
  slug: string
  website: string
  children: ReactNode
}) {
  return (
    <div className="flex flex-col justify-between">
      <Header />
      <main>
        {children}
        {/* list of slugs to now show the My Genting App advert */}
        {!['home-genting-uk', 'my-genting-rewards-app', undefined].includes(
          slug
        ) &&
          website === 'genting-casinos' && <MyGentingApp />}
      </main>
      <Footer />
      <CookieManagement />
    </div>
  )
}

function LayoutHeadless({ children }: { children: ReactNode }) {
  return (
    <div>
      <main>{children}</main>
    </div>
  )
}

export { Layout, LayoutHeadless }
