import dynamic from 'next/dynamic'
import tw from 'twin.macro'

import { useBreakPoint } from '@/hooks/use-break-point'
import { Container } from '@/atoms/container'
import { NextLink } from '@/atoms/next-link'
import { PalmBeachLogo } from '@/atoms/pblogo'
import { CrockfordsLogo } from '@/atoms/crockfords-logo'
import { useWebsite } from '@/hooks/use-website'

const LeftHandMenu = dynamic<{}>(
  () => import('.//left-hand-menu').then((module) => module.LeftHandMenu),
  { ssr: false },
)

export const TopNavLC = () => {
  const isMediumScreen = useBreakPoint('lg')
  const website = useWebsite()

  const getLogo = (website: string) => {
    switch (website) {
      case 'the-palm-beach':
        return <PalmBeachLogo />

      case 'crockfords':
        return <CrockfordsLogo />
    }
  }

  return (
    <div tw="bg-theme-secondary">
      <Container
        className={`flex flex-row items-center justify-between py-3 lg:border-b-2 ${
          website === 'crockfords'
            ? 'lg:border-white'
            : 'lg:border-gray-100 lg:border-opacity-50'
        } `}>
        <div tw="flex-1">{isMediumScreen && <LeftHandMenu />}</div>
        <div tw="flex-1">
          <NextLink
            to={`/${website}`}
            tw="flex flex-row items-center justify-center">
            {getLogo(website)}
          </NextLink>
        </div>
        <div tw="flex-1 ml-auto flex justify-end">
          <a
            href="https://www.gentingcasinos.co.uk/register/"
            target="_blank"
            css={[
              tw`bg-theme-accent py-1  lg:hidden focus:outline-none focus:ring-2 focus:ring-theme-secondary hover:cursor-pointer`,
              website === 'crockfords' && tw`hidden`,
            ]}>
            Register
          </a>
        </div>
      </Container>
    </div>
  )
}
