import { memo } from 'react'
import dynamic from 'next/dynamic'
import tw from 'twin.macro'

import { useBreakPoint } from '@/hooks/use-break-point'

import { useWebsite } from '@/hooks/use-website'

import { Container } from '@/atoms/container'
import { CompanyInformation } from '@/atoms/company-information'
import { CompanyInformationLondon } from '@/components/london-clubs/atoms/company-information'
import { Divider } from '@/atoms/divider'
import { SRlogos } from '@/atoms/srlogos'
import { UpIconMobile } from '@/atoms/mobile-up-icon'

import { SocialArea } from '@/molecules/social-area'

// if component doesn't have props, just define
// https://github.com/vercel/next.js/issues/22278#issuecomment-947319103
const DesktopFooterLinks = dynamic<{}>(
  () => import('./footer-links').then((module) => module.FooterLinks),
  { ssr: false }
)
const MobileFooterLinks = dynamic<{}>(
  () =>
    import('../molecules/footer-links-mobile').then(
      (module) => module.FooterLinksMobile
    ),
  { ssr: false }
)

const Footer = memo(function MemorizedFooter() {
  const isMediumScreen = useBreakPoint('md')
  const website = useWebsite()

  return (
    <footer className="relative z-20 bg-theme-secondary">
      <UpIconMobile />
      <Container tw="py-12 md:py-24">
        <SocialArea />
      </Container>
      <Divider />
      <Container tw="py-12 md:py-24">
        {isMediumScreen ? <MobileFooterLinks /> : <DesktopFooterLinks />}
      </Container>
      <Divider />
      <Container tw="py-12 md:py-24">
        <SRlogos />
      </Container>
      <Divider />
      <Container tw="py-12 md:py-24">
        {website === 'genting-casinos' ? <CompanyInformation /> : <CompanyInformationLondon />}
      </Container>
    </footer>
  )
})

export { Footer }