import { Socials } from '@/components/atoms/socials'
import { GentingLogoWhite } from '@/components/atoms/genting-white-logo'
import { TPBLogoWhite } from '@/tpb/atoms/tpb-logo'
import { CrockfordsLogo } from '@/components/london-clubs/atoms/crockfords-logo'
import { NextLink } from '@/atoms/next-link'

import { usePathPrefix } from '@/hooks/use-path-prefix'
import { useWebsite } from '@/hooks/use-website'

export const SocialArea = () => {
  const pathPrefix = usePathPrefix()
  const website = useWebsite()

  return (
    <section className="flex justify-center m-auto px-6 text-center text-xxs">
      <div className="min-w-min max-w-min">
        <NextLink to={`${pathPrefix}/`}>
          {website === 'genting-casinos' && <GentingLogoWhite />}
          {website === 'the-palm-beach' && <TPBLogoWhite />}
          {website === 'crockfords' && <CrockfordsLogo />}
        </NextLink>
        <Socials />
      </div>
    </section>
  )
}
