export function ExitPreviewBanner({ preview } : { preview: boolean }) {
  
  async function exitPreviewMode() {
    const res = await fetch('/api/exit-preview').catch(err => console.error(err))

    if (res) {
      window.close()
    }
  }

  return (
    <>
      {preview ? (
        <div className="relative bg-yellow-500">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium text-white">
                <span>
                  Preview mode is on,
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  <button className="text-white font-bold underline" onClick={() => exitPreviewMode()}> turn off</button>
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )

}
