import { useQuery } from 'react-query'

import { fetchAPI } from '@/lib/api'

const getSingle = async (
  path: string,
  query: object = {},
  preview?: boolean | undefined
) => {
  const res = await fetchAPI(path, query, preview)

  return res
}

const useSingle = (path: string, query?: object, preview?: boolean) => {
  return useQuery([path, { ...query }], () => getSingle(path, query, preview))
}

const getCollection = async (
  path: string,
  query?: object,
  preview?: boolean
) => {
  const res = await fetchAPI(path, query, preview)

  return res
}

const useCollection = (path: string, query: object = {}) => {
  // spreading the query object so we don't get a key of null
  // should be an empty object if query is nul
  return useQuery([path, { ...query }], () => getCollection(path, query))
}

export { getSingle, useSingle, getCollection, useCollection }
