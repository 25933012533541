import qs from 'qs'

import config from '@/lib/config'

export enum ApiEnv {
  CMS = 'cms',
  RegApi = 'registrationApi',
  jackpotApi = 'jackpotApi'
}

const STAGE: 'string' = process.env.NEXT_PUBLIC_APP_ENV

export async function fetchAPI(
  path: string,
  query?: object | null,
  preview: boolean = false,
  apiEnv: ApiEnv = ApiEnv.CMS
) {
  const { host, registrationApi, jackpotApi } = config

  let hostUrl

  switch(apiEnv) {
    case ApiEnv.RegApi:
      hostUrl = registrationApi
      break
    case ApiEnv.jackpotApi:
      hostUrl = jackpotApi
      break
    case ApiEnv.CMS:
    default:
      hostUrl = host
      break
  }

  const fetchURL = `${hostUrl}/${path}?${
    query ? `${qs.stringify(query)}` : ''
  }${apiEnv === ApiEnv.CMS && preview ? '&publicationState=preview' : ''}`

  let res: any = ''

  // If registration api set origin header for prefetch queries
  if (apiEnv !== ApiEnv.CMS) {
    const options = {
      headers: {
        origin: STAGE === 'local' ? 'localhost:3000' : 'gentingcasinos.co.uk',
        ...(apiEnv === ApiEnv.RegApi ? { 'x-api-key': `${process.env.REGISTRATION_API}` } : {} )
      },
    }

    res = await fetch(fetchURL, options).catch((err) => {
      console.error(err)
      throw new Error('Failed to fetch API')
    })

    return await res.json().catch((err) => {
      console.error(res)
    })
  }

  // use external proxy to bypass AWS Amplify to Strapi as Amplify is https
  // but the dev Strapi load balanacer is http
  if (STAGE === 'development') {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: fetchURL }),
    }

    //due to typescript, can't return .json from then immediately, which makes this less appealing
    res = await fetch(
      `https://api-proxy-smoky.vercel.app/api/proxy`,
      options
    ).catch((err) => {
      console.error(err)
      throw new Error('Failed to fetch API')
    })
  } else {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    //due to typescript, can't return .json from then immediately, which makes this less appealing
    res = await fetch(fetchURL, options).catch((err) => {
      console.error(err)
      throw new Error('Failed to fetch API')
    })
  }

  return await res.json().catch((err) => {
    console.error(res)
  })
}
