import { useEffect, RefObject } from 'react'

/*
* https://usehooks.com/useOnClickOutside/
*/

export function useOnClickOutside(ref: RefObject<HTMLUListElement | HTMLDivElement>, handler: (arg0: Event) => void) {
  useEffect(() => {
    const listener = (event: Event) => {
      // do nothing if clicking ref element or direct descendant
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }      

      handler(event)
    }
    
    document.addEventListener('pointerdown', listener)
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('pointerdown', listener)
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  })

  // Add ref and handler to effect dependencies
  // It's worth noting that because passed in handler is a new ...
  // ... function on every render that will cause this effect ...
  // ... callback/cleanup to run every render. It's not a big deal ...
  // ... but to optimize you can wrap handler in useCallback before ...
  // ... passing it into this hook.  
  return [ref, handler]
}