import { useState, useEffect } from 'react';

export function useMediaQuery(mediaQuery: string, ssrDefault: boolean = false) {
  const [ matches, setMatches ] = useState(() => {

    if (typeof window === 'undefined') {
      return ssrDefault;
    }

    return window.matchMedia(mediaQuery).matches === true;
  });

  useEffect(() => {
    const matchMedia = window.matchMedia(mediaQuery);

    if (matchMedia.matches !== matches) {
      setMatches(matchMedia.matches);
    }

    const handleChange = () => {
      setMatches(matchMedia.matches);
    };

    try {
      matchMedia.addEventListener('change', handleChange);
    } catch (e) {
      // Safari doesn't support addEventListener yet
      matchMedia.addListener(handleChange);
    }

    return () => {
      try {
        matchMedia.removeEventListener('change', handleChange);
      } catch (e) {
        // Safari doesn't support removeEventListener yet
        matchMedia.removeListener(handleChange);
      }
    };
  }, [ mediaQuery ]);

  return matches;
}
