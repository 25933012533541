import { ReactNode } from 'react'
import Link from 'next/link'
import Markdown from 'markdown-to-jsx'
import tw from 'twin.macro'

const options = {
  overrides: {
    a: ({ children, ...rest }: { children: ReactNode }) => {
      //@ts-ignore
      const link = rest.href
      const isExternal = link.includes('https')
      return (
        <Link href={link} passHref={isExternal}>
          <a target={isExternal ? '_blank' : '_self'} {...rest}>
            {children}
          </a>
        </Link>
      )
    },
  },
}

export function RenderMarkdown({ children = '', ...props }) {
  if (!children) return null //strange markdown error - if children are null, it shuts down
  return (
    <Markdown options={{ overrides: options.overrides }} {...props}>
      {children}
    </Markdown>
  )
}
