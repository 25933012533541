import { ReactNode, useEffect, useRef } from 'react'
import tw, { css, theme } from 'twin.macro'
import { clearBodyLocks, lock, unlock } from 'tua-body-scroll-lock';
import { useMediaQuery } from '@/hooks/use-media-query';

type Props = {
  label: string,
  children: ReactNode,
  isOpen: boolean,
  id: string,
  position?: 'top' | 'right' | 'bottom' | 'left',
};

export function SlideOverInline({ label, children, isOpen = false, id, position = 'left' }: Props) {
  
  const slideOverRef = useRef<null | HTMLDivElement>(null)

  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`);

  useEffect(() => {
    document.querySelector('body')?.classList.toggle('overflow-hidden', isOpen)
    const slideOverScroll = slideOverRef?.current?.scrollHeight as number
    const slideOverClient = slideOverRef?.current?.clientHeight as number

    if(isOpen && (slideOverScroll <= slideOverClient) && !isLargeScreen){
      lock()
    } else {
      unlock()
    }
    return () => {
      clearBodyLocks()
    }
  }, [isOpen])

  return (
    <div css={[
      tw`fixed z-50 inset-0 overflow-hidden pointer-events-none`,
      isOpen && tw`pointer-events-auto`,
    ]} id={id} tabIndex={-1} role="dialog" aria-label={label} aria-hidden={!isOpen}>
      <div css={[
        tw`absolute inset-0 bg-gray-500 opacity-0 bg-opacity-75 transition ease-in-out duration-500 sm:duration-700`,
        isOpen && tw`opacity-100`
      ]} aria-hidden />

      <div css={[
        tw`fixed max-w-full flex transform transition ease-in-out duration-500 sm:duration-700`,
        position === 'top' && tw`inset-x-0 top-0 -translate-y-full`,
        position === 'right' && tw`inset-y-0 right-0 translate-x-full`,
        position === 'bottom' && tw`inset-x-0 bottom-0 translate-y-full`,
        position === 'left' && tw`inset-y-0 left-0 -translate-x-full`,
        isOpen && position === 'top' && tw`translate-y-0`,
        isOpen && position === 'right' && tw`translate-x-0`,
        isOpen && position === 'bottom' && tw`translate-y-0`,
        isOpen && position === 'left' && tw`translate-x-0`,        
      ]}>
        <div css={[
          tw`relative`,
          position === 'top' || position === 'bottom' ? tw`w-screen h-screen max-h-96` : tw`w-screen h-screen max-w-4xl bg-white`,
        ]}>
          <div ref={slideOverRef} css={[
            tw`fixed inset-0 flex flex-col bg-white overflow-y-scroll`,
            css`
							/* adds the safe-area-inset-bottom value to the initial 1em of padding.
							a larger black area will display for a device that has a positive value for this variable. */			
							bottom: env(safe-area-inset-bottom);					
              `
          ]}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
