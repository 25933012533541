import tw, { TwStyle } from "twin.macro";

type Themes = "default" | "accent";
type Variants = "contained" | "containedCurrent" | "inverse" | "text";

const themes: Record<Themes, TwStyle> = {
  default: {
    contained: tw`text-theme-secondary bg-white border-theme-secondary`,
    containedCurrent: tw`text-current bg-white border-theme-secondary`,
    inverse: tw`text-white border-white`,
    text: `text-theme-secondary border-transparent`,
  },
  accent: {
    contained: tw`text-white bg-theme-accent border-theme-accent`,
    containedCurrent: tw`text-current bg-theme-accent border-theme-accent`,
    inverse: tw`text-theme-accent border-theme-accent`,
    text: tw`text-theme-accent border-transparent`,
  },
};

const styles = {
  button: ({ theme, variant }: { theme: Themes; variant: Variants }) => [
    themes[theme][variant],
  ],
};

function Button({
  type = "button",
  variant = "contained",
  theme = "default",
  disabled = false,
  fullWidth = false,
  onClick,
  children,
  ...rest
}: {
  className?: string;
  type?: "button" | "submit";
  theme?: Themes;
  variant?: Variants;
  disabled?: true | false;
  fullWidth?: true | false;
  onClick?: () => void;
  children?: React.ReactNode;
  id?: string;
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      css={[
        tw`p-2 text-center border focus:(outline-none ring-2 ring-red-500 border-red-500) disabled:cursor-not-allowed`,
        fullWidth && tw`w-full`,
        styles.button({ theme, variant }),
      ]}
      {...rest}
    >
      {children}
    </button>
  );
}

export { Button };