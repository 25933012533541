import { useEffect, useState, memo } from 'react'
import Link from 'next/link'

import { useRouter } from 'next/router'
import tw, { css } from 'twin.macro'
import Cookies from 'js-cookie'
import { Switch } from '@headlessui/react'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useSingle } from '@/hooks/use-cms-data'
import { useWebsite } from '@/hooks/use-website'

import { Button } from '@/atoms/button'
import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { Icon } from '@/atoms/icon'
import { SlideOverInline } from '@/atoms/slide-over-inline'
import { Typography } from '@/atoms/typography'

type TConsent = {
  functionality_storage: 'denied' | 'granted'
  personalization_storage: 'denied' | 'granted'
  ad_storage: 'denied' | 'granted'
  analytics_storage: 'denied' | 'granted'
  security_storage: 'denied' | 'granted'
}

// Default consents repeated in initialize code as not been
// able to pull them in, likewise gtag is repeated in initialize code
// Tag Manager consent types - https://support.google.com/tagmanager/answer/10718549#tag-settings
// Functionality Cookies = functionality_storage
// Personalisation Cookies = personalization_storage
// Third Party Marketing / Targeting Cookies = ad_storage
// Analytics Cookies = analytics_storage
// Security Cookies = security_storage
// PLEASE NOTE: security cookies are being classed as Strictly Necessary Cookies
// therefore are always on
const CONSENTS = {
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  security_storage: 'granted',
}

export function CookiesBar({
  cookiesBarShow,
  handlePanelOpen,
  acceptAllCookies,
  panelOpen,
}: {
  cookiesBarShow: boolean
  // eslint-disable-next-line no-unused-vars
  handlePanelOpen: (status: boolean) => void
  acceptAllCookies: () => void
  panelOpen: boolean
}) {
  const website = useWebsite()

  return (
    <Container
      data-testid="cookies-bar"
      variant="full"
      as="section"
      css={[
        tw`fixed left-0 transition-opacity duration-1000 bottom-0 right-0 bg-white shadow-[0 -20px 25px -5px rgb(0 0 0 / 0.1),0 0px 10px -6px rgb(0 0 0 / 0.4)]`,
        cookiesBarShow
          ? tw`opacity-100 z-50`
          : css`
              opacity: 0;
              z-index: -1;
            `,
      ]}>
      <Container>
        <div tw="my-5 text-center md:text-left -bottom-full">
          <Heading
            as="h3"
            variant="h3"
            css={[
              tw`text-theme-accent mb-2`,
              website === 'the-palm-beach' && tw`lg:tracking-[3px]`,
            ]}>
            This website uses cookies
          </Heading>
          <div tw="flex flex-col md:flex-row justify-center items-center">
            <p
              css={[
                tw`text-black md:mr-8`,
                website === 'the-palm-beach' && tw`font-light`,
              ]}>
              By clicking ‘Accept All Cookies’, you agree to the storing of
              cookies on your device to enhance site navigation, analyse site
              usage, and assist in our marketing efforts.
              <Link
                href="/cookies-policy"
                tw="underline text-theme-accent ml-0.5 hover:cursor-pointer">
                See our Cookies Policy
              </Link>
              .
            </p>
            <div tw="w-full md:w-auto mt-5 -mx-2.5 flex flex-col md:flex-row justify-center flex-grow">
              <Button
                theme="accent"
                variant="inverse"
                tw="flex-1 md:mx-2.5 inline-flex justify-center md:px-[25.5px] items-center whitespace-nowrap mb-3 md:mb-0"
                onClick={() => handlePanelOpen(!panelOpen)}>
                Manage Cookies
              </Button>
              <Button
                theme={website === 'crockfords' ? 'default' : 'accent'}
                variant={website === 'crockfords' ? 'contained' : 'inverse'}
                css={[
                  tw`flex-1 md:mx-2.5 inline-flex justify-center md:px-[35.5px] items-center whitespace-nowrap`,
                  website === 'crockfords' &&
                    tw`bg-theme-accent text-white border-theme-accent`,
                ]}
                onClick={acceptAllCookies}
                data-testid="accept-cookies">
                Accept All Cookies
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  )
}

const CookieManagement = memo(function MemorizedCookieManagement() {
  const router = useRouter()
  const [panelOpen, setPanelOpen] = useState(false)
  const [cookiesBarShow, setCookiesBarShow] = useState(false)
  const [consents, setConsents] = useState(CONSENTS)

  const [cookiesPanelOpen, setCookiesPanelOpen] = useState(false)
  const { data: cookiesPolicyData } = useSingle('pages', {
    slug: 'cookies-policy',
  })
  const website = useWebsite()

  function handlePanelOpen(status: boolean) {
    setPanelOpen(status)

    const bodyEl = document?.querySelector('body')
    if (bodyEl && status === true) {
      bodyEl.classList.add('overflow-hidden')
    } else if (bodyEl && status === false) {
      bodyEl.classList.remove('overflow-hidden')
    }
  }

  useEffect(() => {
    const cookies = Cookies.get('guk_cookies_consent')

    //** Show Cookies popup if no cookies are set */
    if (!cookies) {
      setCookiesBarShow(true)
    } else {
      consentGranted(JSON.parse(cookies))
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url.includes('#manage-cookies')) {
        handlePanelOpen(true)
      }
    }

    router.events.on('hashChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('hashChangeStart', handleRouteChange)
    }
  }, [])

  function consentGranted(entries?: TConsent) {
    handlePanelOpen(false)
    setCookiesBarShow(false)

    // Update this section based on consent granted
    // if coming from accept all typeof will be an object
    // otherwise Manage Cookies was used and consents will be
    // in useState
    const updatedConsents = typeof entries === 'object' ? entries : consents

    // Add if the cookie doesn't exist or we are updating the consent values
    Cookies.set('guk_cookies_consent', JSON.stringify(updatedConsents), {
      expires: 365,
    })

    // update state for switches to show correct permission
    setConsents(updatedConsents)

    /* @ts-ignore */
    window.gtag('consent', 'update', updatedConsents)

    /* @ts-ignore */
    window.dataLayer &&
      window.dataLayer.push({
        event: 'consent-update',
      })

    // remove scripts after consent update
    removeScripts(updatedConsents)
  }

  function removeScripts(updatedConsents) {
    Object.keys(updatedConsents).forEach((key) => {
      if (updatedConsents[key] === 'denied') {
        const scriptEls = document.querySelectorAll(`[id*=${key}]`)

        Array.from(scriptEls).forEach((script) => script.remove())
      }
    })
  }

  function acceptAllCookies() {
    // CONSENTS in useState don't update in time
    // therefore loop through entries
    // and set all to granted
    const entries = Object.assign(CONSENTS)

    Object.keys(consents).forEach((key) => {
      entries[key] = 'granted'
    })

    consentGranted(entries)
  }

  function rejectAllCookies() {
    const entries = Object.assign(CONSENTS)

    Object.keys(consents).forEach((key) => {
      if (key === 'security_storage') return

      entries[key] = 'denied'
    })

    consentGranted(entries)
  }

  return (
    <>
      <CookiesBar
        cookiesBarShow={cookiesBarShow}
        handlePanelOpen={handlePanelOpen}
        acceptAllCookies={acceptAllCookies}
        panelOpen={panelOpen}
      />

      {/* SORT BY SLIDER */}
      <SlideOverInline
        label="Cookies"
        isOpen={panelOpen}
        position="left"
        id="cookies-panel">
        <div tw="flex flex-col flex-1 bg-[#f9f9f9]">
          <div tw="flex justify-end items-center text-black p-5 px-8">
            <button onClick={() => handlePanelOpen(false)}>
              <Icon icon="close" variant="outline" tw="w-3.5 h-3.5" />
            </button>
          </div>
          <div tw="flex-1 px-8">
            <div tw="mb-5">
              <h5
                css={[
                  tw`text-theme-accent text-sm`,
                  website === 'the-palm-beach' && tw`font-sans! uppercase`,
                ]}>
                What Are Cookies?
              </h5>
              <p tw="text-black text-sm">
                We use cookies and similar technologies on this site. Cookies
                are small text files that are set by website servers and stored
                on your browser or device. Similar technologies include pixels,
                beacons, gifs and scripts. Wherever we refer to "cookies" on
                this site, we are referring to similar technologies as well. We
                outline our use of cookies and similar technologies here. You
                can alter your cookie preference settings at any time via the
                'Manage Cookies' tool located in the website footer
              </p>
            </div>
            <div tw="mb-5">
              <Button
                theme="accent"
                variant="contained"
                tw="flex-1 w-full inline-flex justify-center items-center whitespace-nowrap mb-8"
                onClick={() => acceptAllCookies()}>
                Accept All Cookies
              </Button>
              <h5
                css={[
                  tw`text-theme-accent text-sm`,
                  website === 'the-palm-beach' && tw`font-sans! uppercase`,
                ]}>
                Manage Consent Preferences
              </h5>
            </div>
            <div tw="mb-5">
              <div tw="flex justify-between items-center mb-5">
                <h5 css={[tw`text-theme-accent text-sm`]}>
                  Strictly Necessary Cookies
                </h5>
                <span tw="text-[#294E4F] text-sm">Always Active</span>
              </div>
              <p tw="text-black text-sm">
                Strictly Necessary cookies are essential for core functionality
                such as site security, network management and accessibility.
                They also assist us in identifying your device and location and
                help present you with a compatible version of our website. We do
                not require your consent to Strictly Necessary cookies, but you
                may disable them by changing your web browser settings. This may
                affect how our site functions and some features may not display
                correctly if they are removed. &nbsp;
                <button
                  onClick={() => setCookiesPanelOpen(true)}
                  tw="text-theme-accent underline hover:text-theme-accent">
                  Cookies Policy
                </button>
              </p>
            </div>
            <div tw="mb-5">
              <div tw="flex justify-between items-center mb-5">
                <h5
                  css={[
                    tw`text-theme-accent text-sm`,
                    website === 'the-palm-beach' && tw`font-sans!`,
                  ]}>
                  Functionality Cookies
                </h5>
                <Switch
                  checked={consents.functionality_storage === 'granted'}
                  onChange={() => {
                    setConsents({
                      ...consents,
                      functionality_storage:
                        consents.functionality_storage === 'granted'
                          ? 'denied'
                          : 'granted',
                    })
                  }}
                  css={[
                    tw`relative inline-flex items-center h-6 rounded-full w-11 bg-gray-900`,
                    consents.functionality_storage === 'granted' &&
                      tw`bg-theme-accent`,
                  ]}>
                  <span className="sr-only">Enable functional cookies</span>
                  <span
                    css={[
                      tw`inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`,
                      consents.functionality_storage === 'granted'
                        ? tw`translate-x-6`
                        : tw`translate-x-1`,
                    ]}
                  />
                </Switch>
              </div>
              <p tw="text-black text-sm">
                Functionality cookies are used to recognise you when you return
                to our sites and to remember your site preferences such as
                language selection and any personalised settings such as cookie
                preferences. Site functionality, user experience and your
                personalised site preferences may be affected if Functionality
                cookies are declined. &nbsp;
                <button
                  onClick={() => setCookiesPanelOpen(true)}
                  tw="text-theme-accent underline hover:text-theme-accent">
                  Cookies Policy
                </button>
              </p>
            </div>
            <div tw="mb-5">
              <div tw="flex justify-between items-center mb-5">
                <h5
                  css={[
                    tw`text-theme-accent text-sm`,
                    website === 'the-palm-beach' && tw`font-sans!`,
                  ]}>
                  Personalisation and Targeting Cookies
                </h5>
                <Switch
                  checked={consents.personalization_storage === 'granted'}
                  onChange={() => {
                    setConsents({
                      ...consents,
                      personalization_storage:
                        consents.personalization_storage === 'granted'
                          ? 'denied'
                          : 'granted',
                      ad_storage:
                        consents.ad_storage === 'granted'
                          ? 'denied'
                          : 'granted',
                    })
                  }}
                  css={[
                    tw`relative inline-flex items-center h-6 rounded-full w-11 bg-gray-900`,
                    consents.personalization_storage === 'granted' &&
                      tw`bg-theme-accent`,
                  ]}>
                  <span className="sr-only">
                    Enable personalisation and targeting cookies
                  </span>
                  <span
                    css={[
                      tw`inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`,
                      consents.personalization_storage === 'granted'
                        ? tw`translate-x-6`
                        : tw`translate-x-1`,
                    ]}
                  />
                </Switch>
              </div>
              <p tw="text-black text-sm">
                We may use cookies to deliver content that is relevant to your
                preferences. This may take the form of advertisements on other
                websites, such as Genting Casinos related banners on social
                media sites. In some instances, we may use selected third-party
                cookies to facilitate the advertising of our products and
                services and to help us monitor how our marketing campaigns are
                performing. These third parties are also likely to use cookies
                and similar technologies in their own right in order to deliver
                advertising that may be of interest to you when visiting other
                websites or social media pages. &nbsp;
                <button
                  onClick={() => setCookiesPanelOpen(true)}
                  tw="text-theme-accent underline hover:text-theme-accent">
                  Cookies Policy
                </button>
              </p>
            </div>
            <div tw="mb-15">
              <div tw="flex justify-between items-center mb-5">
                <h5
                  css={[
                    tw`text-theme-accent text-sm`,
                    website === 'the-palm-beach' && tw`font-sans!`,
                  ]}>
                  Analytics Cookies
                </h5>
                <Switch
                  checked={consents.analytics_storage === 'granted'}
                  onChange={() => {
                    setConsents({
                      ...consents,
                      analytics_storage:
                        consents.analytics_storage === 'granted'
                          ? 'denied'
                          : 'granted',
                    })
                  }}
                  css={[
                    tw`relative inline-flex items-center h-6 rounded-full w-11 bg-gray-900`,
                    consents.analytics_storage === 'granted' &&
                      tw`bg-theme-accent`,
                  ]}>
                  <span className="sr-only">
                    Enable Third Party Marketing / Targeting cookies
                  </span>
                  <span
                    css={[
                      tw`inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`,
                      consents.analytics_storage === 'granted'
                        ? tw`translate-x-6`
                        : tw`translate-x-1`,
                    ]}
                  />
                </Switch>
              </div>
              <p tw="text-black text-sm">
                We'd like to set Google Analytics cookies to help us improve our
                website by collecting and reporting information on visitor
                numbers and how visitors use our site. These cookies are used to
                generate aggregated statistics by collecting information in a
                way that does not directly identify you. We use this aggregated
                data to review the performance of our site and to identify new
                features and improvements for user experience purposes.&nbsp;
                <button
                  onClick={() => setCookiesPanelOpen(true)}
                  tw="text-theme-accent underline hover:text-theme-accent">
                  Cookies Policy
                </button>
              </p>
            </div>
          </div>
          <div tw="sticky bottom-0 p-5 bg-white flex flex-col gap-5 shadow-up">
            <Button
              theme="accent"
              variant="contained"
              tw="flex-1 mx-2.5 inline-flex justify-center items-center"
              onClick={() => consentGranted()}>
              Confirm My Choices
            </Button>

            <Button
              theme="accent"
              variant="inverse"
              tw="flex-1 mx-2.5 inline-flex justify-center items-center"
              onClick={() => rejectAllCookies()}>
              Reject All Cookies
            </Button>
          </div>
        </div>
      </SlideOverInline>

      {cookiesPolicyData && cookiesPolicyData.attributes && (
        <SlideOverInline
          label="Cookies Policy"
          isOpen={cookiesPanelOpen}
          position="left"
          id="cookies-panel">
          <div tw="flex flex-col flex-1 bg-[#f9f9f9]">
            <div tw="flex justify-start items-center text-black p-5 px-8">
              <button onClick={() => setCookiesPanelOpen(false)}>
                <Icon icon="close" variant="outline" tw="w-3.5 h-3.5" />
              </button>
            </div>
            <div tw="flex-1 px-8 text-theme-secondary">
              {/* @ts-ignore */}
              {cookiesPolicyData.attributes.content_zone.map((block, index) => {
                if (block.__component === 'content.heading') {
                  return (
                    <Heading
                      key={index}
                      variant="h3"
                      tw="mb-5 text-theme-accent">
                      {block.heading}
                    </Heading>
                  )
                }

                if (block.__component === 'content.full-width-copy') {
                  return (
                    <Typography key={index} theme="default">
                      <RenderMarkdown>{block.copy}</RenderMarkdown>
                    </Typography>
                  )
                }
              })}
            </div>
            <div tw="sticky bottom-0 p-5 bg-white flex flex-col gap-5">
              <Button
                theme="accent"
                variant="inverse"
                tw="flex-1 mx-2.5 inline-flex justify-center items-center"
                onClick={() => setCookiesPanelOpen(false)}>
                Close Cookies Policy
              </Button>
            </div>
          </div>
        </SlideOverInline>
      )}

      {consents.ad_storage === 'granted' && (
        <img
          src="https://www.facebook.com/tr?id=3358874584362642&ev=PageView&noscript=1"
          height="1"
          width="1"
          tw="hidden"
        />
      )}
    </>
  )
})

export { CookieManagement }
