import { ChevronUpIcon } from '@heroicons/react/solid'
import { useEffect, useState, useRef } from 'react'
import { theme } from 'twin.macro'
import { useMediaQuery } from '@/hooks/use-media-query'
import { useWebsite } from '@/hooks/use-website'

export const UpIconMobile = () => {
  const [showScroll, setShowScroll] = useState(false)

  const typeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const isMediumScreen = useMediaQuery(`(min-width: ${theme('screens.md')})`)

  const website = useWebsite()

  const scrollToTop = () => {
    setShowScroll(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    function checkScrollTop() {
      if (typeTimeout.current) {
        clearTimeout(typeTimeout.current)
      }
      typeTimeout.current = setTimeout(() => {
        if (!showScroll && window.pageYOffset > 800) {
          setShowScroll(true)
        } else if (window.pageYOffset <= 800) {
          setShowScroll(false)
        }
      }, 600)
    }

    //if not mobile, no need for scrollToTop
    if (isMediumScreen)
      return () => window.removeEventListener('scroll', checkScrollTop)
    //make event Listener for scroll, fire checkScrollTop
    window.addEventListener('scroll', checkScrollTop)

    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [isMediumScreen])

  return (
    <div
      className={`${
        showScroll ? 'block' : 'hidden'
      } md:hidden h-6 w-6 fixed z-30 bottom-0 right-0 mr-6 mb-6 bg-white rounded-sm`}
      onClick={scrollToTop}>
      {website === 'genting-casinos' && (
        <ChevronUpIcon className="text-black" />
      )}
      {website !== 'genting-casinos' && (
        <ChevronUpIcon className="text-theme-accent border border-theme-accent" />
      )}
    </div>
  )
}
