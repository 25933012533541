/// <reference types="@emotion/react/types/css-prop" />
import { useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ReactQueryDevtools } from 'react-query/devtools'

import GlobalStyles from '@/styles/GlobalStyles'
import '@/styles/globals.css'

import { ExitPreviewBanner } from '@/molecules/exit-preview-banner'

import { Layout } from '@/organisms/layout'

type AppPropsExt = {
  // eslint-disable-next-line no-unused-vars
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactElement) => ReactNode
  }
  pageProps: AppProps['pageProps']
}

function MyApp({ Component, pageProps }: AppPropsExt) {
  // Use the (headless) layout from page || default layout
  const getLayout =
    Component.getLayout ||
    ((page: any) => (
      <Layout slug={pageProps.slug} website={pageProps.website}>
        {page}
      </Layout>
    ))

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            staleTime: Infinity,
          },
        },
      })
  )

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
      })
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Head>
          {/* Standard meta */}
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />

          {/* Android meta */}
          <meta name="theme-color" content="#7b030b" />
          <meta name="mobile-web-app-capable" content="yes" />

          {/* iOS meta */}
          <meta name="apple-mobile-web-app-title" content="Genting Casinos" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="default"
          />

          {/* Windows meta */}
          <meta name="msapplication-navbutton-color" content="#7b030b" />
          <meta name="msapplication-config" content="/browserconfig.xml" />
          <meta name="application-name" content="Genting Casinos" />
          <meta name="msapplication-tooltip" content="Genting Casinos" />
          <meta
            name="msapplication-starturl"
            content="/?utm_source=homescreen"
          />
          <meta name="msapplication-tap-highlight" content="no" />

          {/* Manifest */}
          <link
            rel="manifest"
            href="/manifest.webmanifest"
            crossOrigin="anonymous"
          />

          {/* Standard icons */}
          <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          <link rel="alternate icon" href="/favicon.ico" />
          <link rel="mask-icon" href="/mask-icon.svg" color="#e6000a" />

          {/* For non-Retina iPhone, iPod Touch, and Android 2.1+ devices */}
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/pwa/apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/pwa/apple-touch-icon-60x60.png"
          />
          {/* For first and second generation iPad */}
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/pwa/apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/pwa/apple-touch-icon-76x76.png"
          />
          {/* For iPhone with high-resolution Retina display running iOS ≤ 6 */}
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/pwa/apple-touch-icon-114x114.png"
          />
          {/* For iPhone with high-resolution Retina display running iOS ≥ 7 */}
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/pwa/apple-touch-icon-120x120.png"
          />
          {/* For iPad with high-resolution Retina display running iOS ≤ 6 */}
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/pwa/apple-touch-icon-144x144.png"
          />
          {/* For iPad with high-resolution Retina display running iOS ≥ 7 */}
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/pwa/apple-touch-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/pwa/apple-touch-icon-180x180.png"
          />

          {/* iPhone Xs Max (1242px x 2688px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
            href="/pwa/apple-launch-1242x2688.png"
          />
          {/* iPhone Xr (828px x 1792px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-828x1792.png"
          />
          {/* iPhone X, Xs (1125px x 2436px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
            href="/pwa/apple-launch-1125x2436.png"
          />
          {/* iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
            href="/pwa/apple-launch-1242x2208.png"
          />
          {/* iPhone 8, 7, 6s, 6 (750px x 1334px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-750x1334.png"
          />
          {/* iPad Pro 12.9" (2048px x 2732px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-2048x2732.png"
          />
          {/* iPad Pro 11” (1668px x 2388px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-1668x2388.png"
          />
          {/* iPad Pro 10.5" (1668px x 2224px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-1668x2224.png"
          />
          {/* iPad Mini, Air (1536px x 2048px) */}
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
            href="/pwa/apple-launch-1536x2048.png"
          />
        </Head>
        <GlobalStyles />
        <ExitPreviewBanner preview={pageProps.preview} />

        {getLayout(<Component {...pageProps} />)}
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default MyApp
