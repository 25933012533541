import { useWebsite } from '@/hooks/use-website'

export const Socials = () => {
  const website = useWebsite()

  return (
    <div className="flex flex-row justify-evenly align-middle px-7">
      {website === 'genting-casinos' && <SocialsGentingDefault />}
      {website === 'the-palm-beach' && <SocialsTPB />}
      {website === 'crockfords' && <SocialsCrockfords />}
    </div>
  )
}

const SocialsGentingDefault = () => {
  return (
    <>
      <a
        href="https://www.linkedin.com/company/genting-casinos-uk"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on LinkedIn. Opens in new window
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.5"
          height="17.5"
          viewBox="0 0 17.5 17.5">
          <path
            id="LinkedIn_icon"
            data-name="LinkedIn icon"
            d="M-4.844,1.25V-10.43H-8.477V1.25Zm-1.8-13.281a2.028,2.028,0,0,0,1.484-.625,2.028,2.028,0,0,0,.625-1.484,2.028,2.028,0,0,0-.625-1.484,2.028,2.028,0,0,0-1.484-.625,2.028,2.028,0,0,0-1.484.625,2.028,2.028,0,0,0-.625,1.484,2.028,2.028,0,0,0,.625,1.484A2.028,2.028,0,0,0-6.641-12.031ZM8.75,1.25V-5.156a7.9,7.9,0,0,0-.781-3.984,3.877,3.877,0,0,0-3.555-1.6,3.851,3.851,0,0,0-2.187.625A3.122,3.122,0,0,0,.977-8.828H.937v-1.6H-2.539V1.25H1.055V-4.531A4.361,4.361,0,0,1,1.445-6.6,1.83,1.83,0,0,1,3.2-7.539a1.644,1.644,0,0,1,1.68,1.055,6.31,6.31,0,0,1,.234,2.031v5.7Z"
            transform="translate(8.75 16.25)"
            fill="#fff"
          />
        </svg>
      </a>

      <a
        href="https://www.youtube.com/user/GentingCasinosUK"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Youtube. Opens in new window
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.328"
          height="15"
          viewBox="0 0 21.328 15"
          style={{ marginTop: '3px' }}>
          <path
            id="YouTube_icon"
            data-name="YouTube icon"
            d="M10.234-12.656a17.652,17.652,0,0,1,.391,3.2L10.664-7.5l-.039,1.953A17.859,17.859,0,0,1,10.234-2.3a2.724,2.724,0,0,1-.7,1.172,2.708,2.708,0,0,1-1.211.7,27.784,27.784,0,0,1-4.766.391L0,0-3.555-.039A27.784,27.784,0,0,1-8.32-.43a2.708,2.708,0,0,1-1.211-.7,2.724,2.724,0,0,1-.7-1.172,17.859,17.859,0,0,1-.391-3.242L-10.664-7.5q0-.859.039-1.953a17.652,17.652,0,0,1,.391-3.2,2.708,2.708,0,0,1,.7-1.211,2.708,2.708,0,0,1,1.211-.7,27.784,27.784,0,0,1,4.766-.391L0-15l3.555.039a27.785,27.785,0,0,1,4.766.391,2.708,2.708,0,0,1,1.211.7A2.708,2.708,0,0,1,10.234-12.656ZM-2.188-4.3,3.4-7.5l-5.586-3.164Z"
            transform="translate(10.664 15)"
            fill="#fff"
          />
        </svg>
      </a>

      <a
        href="https://www.facebook.com/GentingCasinos"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Facebook. Opens in new window
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10.313"
          height="20"
          viewBox="0 0 10.313 20">
          <path
            id="Facebook_icon"
            data-name="Facebook icon"
            d="M-2.148,2.5H1.523V-6.445H4.375L4.844-10H1.523v-2.461a2,2,0,0,1,.312-1.25,1.814,1.814,0,0,1,1.445-.469H5.156v-3.164A19.347,19.347,0,0,0,2.422-17.5,4.528,4.528,0,0,0-.9-16.269a4.632,4.632,0,0,0-1.25,3.457V-10H-5.156v3.555h3.008Z"
            transform="translate(5.156 17.5)"
            fill="#fff"
          />
        </svg>
      </a>

      <a
        href="https://www.instagram.com/gentingcasinosuk/"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Instagram. Opens in new window
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="19.996"
          viewBox="0 0 20 19.996">
          <path
            id="Instagram_Icon"
            data-name="Instagram Icon"
            d="M10-11.386A5.118,5.118,0,0,0,4.873-6.259,5.118,5.118,0,0,0,10-1.132a5.118,5.118,0,0,0,5.127-5.127A5.118,5.118,0,0,0,10-11.386Zm0,8.46A3.339,3.339,0,0,1,6.666-6.259,3.336,3.336,0,0,1,10-9.592a3.336,3.336,0,0,1,3.333,3.333A3.339,3.339,0,0,1,10-2.926ZM16.531-11.6a1.2,1.2,0,0,0-1.2-1.2,1.2,1.2,0,0,0-1.2,1.2,1.193,1.193,0,0,0,1.2,1.2A1.193,1.193,0,0,0,16.531-11.6Zm3.4,1.214a5.917,5.917,0,0,0-1.615-4.19,5.957,5.957,0,0,0-4.19-1.615c-1.651-.094-6.6-.094-8.25,0a5.948,5.948,0,0,0-4.19,1.611,5.937,5.937,0,0,0-1.615,4.19c-.094,1.651-.094,6.6,0,8.25a5.918,5.918,0,0,0,1.615,4.19,5.964,5.964,0,0,0,4.19,1.615c1.651.094,6.6.094,8.25,0a5.918,5.918,0,0,0,4.19-1.615,5.957,5.957,0,0,0,1.615-4.19C20.021-3.787,20.021-8.731,19.927-10.382ZM17.794-.365a3.374,3.374,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.374,3.374,0,0,1-1.9-1.9C1.683-1.681,1.8-4.8,1.8-6.259s-.116-4.582.4-5.894a3.374,3.374,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.374,3.374,0,0,1,1.9,1.9c.522,1.316.4,4.439.4,5.894S18.316-1.677,17.794-.365Z"
            transform="translate(0.003 16.257)"
            fill="#fff"
          />
        </svg>
      </a>
    </>
  )
}

const SocialsTPB = () => {
  return (
    <div className="flex flex-row w-full justify-center gap-5">
      <a
        href="https://www.facebook.com/PalmBeachMayfair"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Facebook. Opens in new window
        </span>
        <svg
          width="10.313"
          height="20"
          viewBox="0 0 10.313 20"
          className="fill-current text-white hover:text-theme-gold">
          <path
            id="Facebook_icon"
            data-name="Facebook icon"
            d="M-2.148,2.5H1.523V-6.445H4.375L4.844-10H1.523v-2.461a2,2,0,0,1,.312-1.25,1.814,1.814,0,0,1,1.445-.469H5.156v-3.164A19.347,19.347,0,0,0,2.422-17.5,4.528,4.528,0,0,0-.9-16.269a4.632,4.632,0,0,0-1.25,3.457V-10H-5.156v3.555h3.008Z"
            transform="translate(5.156 17.5)"
          />
        </svg>
      </a>

      <a
        href="https://www.instagram.com/Palmbeachmayfair/"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Instagram. Opens in new window
        </span>
        <svg
          width="20"
          height="19.996"
          viewBox="0 0 20 19.996"
          className="fill-current text-white hover:text-theme-gold">
          <path
            id="Instagram_Icon"
            data-name="Instagram Icon"
            d="M10-11.386A5.118,5.118,0,0,0,4.873-6.259,5.118,5.118,0,0,0,10-1.132a5.118,5.118,0,0,0,5.127-5.127A5.118,5.118,0,0,0,10-11.386Zm0,8.46A3.339,3.339,0,0,1,6.666-6.259,3.336,3.336,0,0,1,10-9.592a3.336,3.336,0,0,1,3.333,3.333A3.339,3.339,0,0,1,10-2.926ZM16.531-11.6a1.2,1.2,0,0,0-1.2-1.2,1.2,1.2,0,0,0-1.2,1.2,1.193,1.193,0,0,0,1.2,1.2A1.193,1.193,0,0,0,16.531-11.6Zm3.4,1.214a5.917,5.917,0,0,0-1.615-4.19,5.957,5.957,0,0,0-4.19-1.615c-1.651-.094-6.6-.094-8.25,0a5.948,5.948,0,0,0-4.19,1.611,5.937,5.937,0,0,0-1.615,4.19c-.094,1.651-.094,6.6,0,8.25a5.918,5.918,0,0,0,1.615,4.19,5.964,5.964,0,0,0,4.19,1.615c1.651.094,6.6.094,8.25,0a5.918,5.918,0,0,0,4.19-1.615,5.957,5.957,0,0,0,1.615-4.19C20.021-3.787,20.021-8.731,19.927-10.382ZM17.794-.365a3.374,3.374,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.374,3.374,0,0,1-1.9-1.9C1.683-1.681,1.8-4.8,1.8-6.259s-.116-4.582.4-5.894a3.374,3.374,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.374,3.374,0,0,1,1.9,1.9c.522,1.316.4,4.439.4,5.894S18.316-1.677,17.794-.365Z"
            transform="translate(0.003 16.257)"
          />
        </svg>
      </a>
    </div>
  )
}

const SocialsCrockfords = () => {
  return (
    <div className="flex flex-row w-full justify-around">
      <a
        href="https://www.facebook.com/pages/Crockfords%20casino/202963830064683/"
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Facebook. Opens in new window
        </span>
        <svg
          width="10.313"
          height="20"
          viewBox="0 0 10.313 20"
          className="fill-current text-white hover:text-theme-gold">
          <path
            id="Facebook_icon"
            data-name="Facebook icon"
            d="M-2.148,2.5H1.523V-6.445H4.375L4.844-10H1.523v-2.461a2,2,0,0,1,.312-1.25,1.814,1.814,0,0,1,1.445-.469H5.156v-3.164A19.347,19.347,0,0,0,2.422-17.5,4.528,4.528,0,0,0-.9-16.269a4.632,4.632,0,0,0-1.25,3.457V-10H-5.156v3.555h3.008Z"
            transform="translate(5.156 17.5)"
          />
        </svg>
      </a>

      <a
        href="https://instagram.com/crockfordsclub?igshid=MzRlODBiNWFlZA=="
        target="_blank"
        rel="noopener noreferrer">
        <span className="sr-only">
          Genting Casinos on Instagram. Opens in new window
        </span>
        <svg
          width="20"
          height="19.996"
          viewBox="0 0 20 19.996"
          className="fill-current text-white hover:text-theme-gold">
          <path
            id="Instagram_Icon"
            data-name="Instagram Icon"
            d="M10-11.386A5.118,5.118,0,0,0,4.873-6.259,5.118,5.118,0,0,0,10-1.132a5.118,5.118,0,0,0,5.127-5.127A5.118,5.118,0,0,0,10-11.386Zm0,8.46A3.339,3.339,0,0,1,6.666-6.259,3.336,3.336,0,0,1,10-9.592a3.336,3.336,0,0,1,3.333,3.333A3.339,3.339,0,0,1,10-2.926ZM16.531-11.6a1.2,1.2,0,0,0-1.2-1.2,1.2,1.2,0,0,0-1.2,1.2,1.193,1.193,0,0,0,1.2,1.2A1.193,1.193,0,0,0,16.531-11.6Zm3.4,1.214a5.917,5.917,0,0,0-1.615-4.19,5.957,5.957,0,0,0-4.19-1.615c-1.651-.094-6.6-.094-8.25,0a5.948,5.948,0,0,0-4.19,1.611,5.937,5.937,0,0,0-1.615,4.19c-.094,1.651-.094,6.6,0,8.25a5.918,5.918,0,0,0,1.615,4.19,5.964,5.964,0,0,0,4.19,1.615c1.651.094,6.6.094,8.25,0a5.918,5.918,0,0,0,4.19-1.615,5.957,5.957,0,0,0,1.615-4.19C20.021-3.787,20.021-8.731,19.927-10.382ZM17.794-.365a3.374,3.374,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.374,3.374,0,0,1-1.9-1.9C1.683-1.681,1.8-4.8,1.8-6.259s-.116-4.582.4-5.894a3.374,3.374,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.374,3.374,0,0,1,1.9,1.9c.522,1.316.4,4.439.4,5.894S18.316-1.677,17.794-.365Z"
            transform="translate(0.003 16.257)"
          />
        </svg>
      </a>
    </div>
  )
}
